import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from 'src/app/_service/login.service';
declare let $:any;
@Component({
  selector: 'app-ebaytocken',
  templateUrl: './ebaytocken.component.html',
  styleUrls: ['./ebaytocken.component.css']
})
export class EbaytockenComponent implements OnInit {

  constructor(private route:ActivatedRoute, private urlApi: LoginService) { }

  ngOnInit(): void {
    // $('#ebay-token').modal("show");
    this.route.queryParams.subscribe((params:any)=> {
      console.log(params);
      this.activateUser(params);
    })
  }
  activateUser(params: any) {
    this.urlApi.acceptEbayToken(params.ebaytkn,params.tknexp,params.username,params.userId,params.ebayUserId,params.nexturl).subscribe((resp:any)=>{
      // this.state = 1;
      $('#ebay-token').modal("show");
    }, (error)=>{
      // this.state = 2;
      // $('#ebay-token').modal("show");
    })
  }
}
