<footer class="footer row f_class">
  <div class="col-lg-6 col-sm-12 col-md-6 ">
    © 2022 Sellershub. All Rights Reserved.
  </div>
  <div class="col-lg-6 col-sm-12 col-md-6  align-self-center text-right " style="padding-right: 0px;">
    <div class="d-flex justify-content-end align-items-center">
      Email: support@sellershub.io
    </div>
  </div>
</footer>
