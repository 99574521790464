<header class="topbar">
    <nav class="navbar top-navbar navbar-expand-md navbar-dark">
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <div class="navbar-header">
            <a class="navbar-brand" href="#" (click)="onGoToHomePage()">
                <!-- Logo icon --><b>
                    <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                    <!-- Dark Logo icon -->
                    <!-- <img src="assets/images/logo.png" alt="homepage" class="dark-logo" /> -->
                    <!-- Light Logo icon -->
                    <img src="assets/images/logo_header.png" alt="homepage" class="light-logo img-responsive" />
                </b>
                <!--End Logo icon -->
                <!-- Logo text --><span>
                    <!-- dark Logo text -->
                    <img src="assets/images/logo.png" alt="homepage" class="dark-logo" />
                    <!-- Light Logo text -->
                    <!-- <img src="../assets/images/logo-light-text.png" class="light-logo" alt="homepage" />-->
                </span>
            </a>
        </div>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <div class="navbar-collapse">
            <!-- ============================================================== -->
            <!-- toggle and nav items -->
            <!-- ============================================================== -->
            <ul class="navbar-nav mr-0">
                <!-- This is  -->
                <li (click)="toggleSidemneu()" class="nav-item"> <a
                        class="nav-link nav-toggler d-block d-md-none waves-effect waves-dark"
                        href="javascript:void(0)"><i class="ti-menu"></i></a> </li>
                <li (click)="toggleSidemneu()" class="nav-item"> <a
                        class="nav-link sidebartoggler d-none d-lg-block d-md-block waves-effect waves-dark"
                        href="javascript:void(0)"><i class="icon-menu"></i></a> </li>
                <!-- ============================================================== -->
                <!-- Search -->
                <!-- ============================================================== -->
                <!-- <li class="nav-item">
                    <form class="app-search d-none d-md-block d-lg-block">
                        <input type="text" class="form-control" placeholder="Search & enter">
                    </form>
                </li> -->



            </ul>
            <ul class="navbar-nav my-lg-0"
                *ngIf="allSubscriptions.trialSubscriber == true && customerSubscriptions[0].endDate >= date">
                <li class="nav-item">
                    <!-- <span style="font-size: larger;">Your FREE trial is due to expire in {{expiringDays}}</span> -->
                    <button class="btn btn-sm btn-primary ml-auto rounded-pill" (click)="OpenSubscription();"> Subscribe
                    </button>
                </li>
            </ul>
            <!-- ============================================================== -->
            <!-- User profile and search -->
            <!-- ============================================================== -->
            <ul class="navbar-nav my-lg-0 ml-auto">
                <li class="nav-item" *ngIf="userId == 2756">
                    <div class="mt-3">
                        <select class="custom-select" [(ngModel)]="user" (change)="changeUser($event)">
            <option value="0">Switch Account</option>
            <option value="2756">Brendan</option>
            <option value="2801">Brendon 1</option>
            <option value="2802">Brendon 2</option>
            <option value="2803">Brendon 3</option>
          </select>
                    </div>
                </li>
                <li class="nav-item dropdown" *ngIf="allProducts.length > 0">
                    <a class="nav-link waves-effect waves-dark" href="javascript:void(0)" id="2"
                        (click)="onGoToProducts()" aria-haspopup="true" aria-expanded="false"><i class="fa fa-exclamation-circle fa-lg" style="color: red; "></i>
                        <div class="notify"> <span class="heartbit"></span> <span class="point"> </span> </div>

                    </a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link waves-effect waves-dark blink" href="javascript:void(0)" id="2"
                        (click)="addTab('Announcements')" aria-haspopup="true" aria-expanded="false"> <i
                            class="ti-announcement"></i>
                        <div class="notify"> <span class="heartbit"></span> <span class="point"> </span> </div>

                    </a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link waves-effect waves-dark" href="javascript:void(0)" id="2"
                        (click)="addTab('Releases')" aria-haspopup="true" aria-expanded="false"> <i
                            class="ti-arrow-circle-up"></i>
                        <div class="notify"> <span class="heartbit"></span> <span class="point"> </span> </div>

                    </a>
                </li>

                <!-- <li class="nav-item dropdown">
                    <a class="nav-link waves-effect waves-dark" href="javascript:void(0)" (click)="onGoToMessages()"
                        aria-haspopup="true" aria-expanded="false"> <i class="ti-email"></i>
                        <div class="notify"> <span class="heartbit"></span> <span class="point"></span> </div>
                    </a>
                </li> -->


                <li class="nav-item dropdown u-pro">
                    <!-- <a class="nav-link dropdown-toggle waves-effect waves-dark profile-pic" href=""
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        &nbsp;<i class=" ti-headphone-alt"></i></a> -->

                    <a class="nav-link dropdown-toggle waves-effect waves-dark profile-pic" href=""
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
                            class=" ti-headphone-alt"></i> &nbsp;<i class="fa fa-angle-down"></i></a>

                    <div class="dropdown-menu dropdown-menu-right animated flipInY">
                        <!-- text-->
                        <a  href="https://www.sellershub.io/userguide/" class="dropdown-item" target="_blank" data-close-others="true"><i class="fa fa-book"></i> Knowledgebase</a>
                        <!-- text-->
                        <a (click)="openTicketModal()" href="javascript:void(0)" class="dropdown-item"><i
                                class="ti-user"></i> Create Ticket</a>
                        <a (click)="openTPhoneSupportModal()" href="javascript:void(0)" class="dropdown-item"><i
                                class="ti-headphone"></i> Phone Support</a>

                    </div>
                </li>
                <!-- ============================================================== -->
                <!-- End mega menu -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- User Profile -->
                <!-- ============================================================== -->
                <!-- src="assets/images/users/1.jpg" -->
                <li class="nav-item dropdown u-pro">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark profile-pic" href=""
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img
                            src="assets/images/user_profile_v2.jpeg" alt="user" class=""> <span class="hidden-md-down">
                            {{name}}&nbsp;({{email}})
                            &nbsp;<i class="fa fa-angle-down"></i></span> </a>
                    <div class="dropdown-menu dropdown-menu-right animated flipInY">
                        <!-- text-->
                        <a (click)="addTab('My Account')" href="javascript:void(0)" class="dropdown-item" *ngIf="status == 'ACTIVE' ||status=='active'||status=='Active'"><i
                                class="ti-user"></i> My Account</a>
                        <!-- text-->
                        <a (click)="addTab('User Management')" href="javascript:void(0)" class="dropdown-item" *ngIf="(status == 'ACTIVE' || status=='active'||status=='Active') && role != 'SUBUSER'"><i
                                class=" ti-settings"></i> User Management</a>
                        <!-- text-->
                        <a (click)="addTab('Subscrption')" href="javascript:void(0)" class="dropdown-item" *ngIf="status == 'ACTIVE'|| status=='active'||status=='Active'"><i
                                class="ti-wallet"></i> Subscription</a>
                        <!-- <a (click)="addTab('Reedem Coupon')" href="javascript:void(0)" class="dropdown-item"><i
                                class=" ti-gift"></i> Reedem Coupon</a> -->
                        <!-- <a (click)="addTab('User Guides')" href="javascript:void(0)" class="dropdown-item"><i
                                class=" ti-book"></i> User Guides</a> -->
                        <!-- text-->
                        <!-- <div class="dropdown-divider"></div>                        -->
                        <!-- text-->
                        <!-- <div class="dropdown-divider"></div> -->
                        <!-- text-->
                        <a href="javascript:void(0)" (click)="logout()" class="dropdown-item"><i
                                class="fa fa-power-off"></i> Logout</a>
                        <!-- text-->
                    </div>
                </li>
                <!-- ============================================================== -->
                <!-- End User Profile -->
                <!-- ============================================================== -->
                <li class="nav-item right-side-toggle" (click)="toggleRightSidemneu()"> <a
                        class="nav-link  waves-effect waves-light" href="javascript:void(0)"><i
                            class="ti-settings"></i></a></li>
            </ul>
        </div>
    </nav>
</header>

<div id="ticket-modal" class="modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
    style="display: none;">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title" style="color: rgb(255 165 0);font-weight: 400">Create Ticket </h3>

                <button type="button" class="close" data-dismiss="modal" (click)="ticketmodalclosed()"
                    aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <div class="col-md-12 col-lg-12 row " *ngIf="!isSuccessticketsupport">
                    <div class="col-md-6">
                        <h4>Please create ticket with issue or details.</h4>
                        <h4>One of our Customer Advisor will come back to you!</h4>
                        <img src="assets/images/createticket.png" class="img-responsive m-t-5">
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <div class="form-group">
                                <input type="text" id="firstName" [(ngModel)]="createTicket.name" class="form-control"
                                    placeholder="Your Name">
                            </div>
                            <div class="form-group">
                                <input type="text" id="email" [(ngModel)]="createTicket.email" class="form-control"
                                    placeholder="Your email">
                            </div>
                            <div class="form-group">
                                <input type="text" id="phone" [(ngModel)]="createTicket.phone" class="form-control"
                                    placeholder="Contact Phone">
                            </div>
                            <div class="form-group">
                                <textarea placeholder="Enter your Query" [(ngModel)]="createTicket.query"
                                    class="form-control" rows="5"></textarea>
                            </div>
                            <label>
                                If applicable, please provide a few examples (SKU, Variant SKUs, Item Ids, Order Number,
                                etc)
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-12 row" *ngIf="isSuccessticketsupport">
                    <h4>Thank you for requesting a call back. You will receive a call back shortly.</h4>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-success"
                    (click)="createTiketSave()" *ngIf="!isSuccessticketsupport">Save</button>
                <button type="button" class="btn btn-danger waves-effect" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div id="psupport-modal" class="modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
    style="display: none;">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header" style="display: block!important;">
                <h6 style="color: rgb(255 165 0);font-weight: 200">Callback hours are 10am - 5pm GMT, Monday - Friday.
                    For requests made outside those times, we'll call you <br>back at the start of the next business
                    day. If you would like to call now please use the below numbers </h6>
                <h4 class="modal-title" style="color: rgb(255 165 0);font-weight: 400">US +1 (925) 217-1261 (8AM EST -
                    5PM EST) UK +44 203 371 7255 (10AM GMT - 5PM GMT)</h4>
                <!-- <h4 class="modal-title" style="color: rgb(255 165 0);font-weight: 400">UK +44 203 371 7255 (10AM GMT - 5PM GMT)</h4> -->

                <button type="button" class="close" data-dismiss="modal" (click)="ticketmodalclosed()"
                    aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <div class="col-md-12 col-lg-12 row" *ngIf="!isSuccessPsupport">
                    <div class="col-md-6">
                        <h4>Leave your details here We will call you back.</h4>
                        <img src="assets/images/girl-callcenter.png" class="img-responsive m-t-5">
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <div class="form-group">
                                <input type="text" id="firstName" [(ngModel)]="createTicket.name" class="form-control"
                                    placeholder="Your Name">
                            </div>
                            <div class="form-group">
                                <input type="text" id="email" [(ngModel)]="createTicket.email" class="form-control"
                                    placeholder="Your email">
                            </div>
                            <div class="form-group">
                                <input type="text" id="phone" [(ngModel)]="createTicket.phone" class="form-control"
                                    placeholder="Contact Phone">
                            </div>
                            <div class="form-group">
                                <textarea placeholder="Enter your Query" [(ngModel)]="createTicket.query"
                                    class="form-control" rows="5"></textarea>
                            </div>
                            <label>
                                If applicable, please provide a few examples (SKU, Variant SKUs, Item Ids, Order Number,
                                etc)
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-12 row" *ngIf="isSuccessPsupport">
                    <h4>Thank you for requesting a call back. You will receive a call back shortly.</h4>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-success" (click)="phoneSupportSave()">Send
                    Request</button>
                <button type="button" class="btn btn-danger waves-effect" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div id="expired-products-modal" class="modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
    style="display: none;">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">

                <!-- <h3 class="modal-title" style="color: rgb(255 165 0);font-weight: 400">Expired Products</h3> -->

                <button type="button" class="close" data-dismiss="modal" (click)="expprodmodalclosed()"
                    aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <ul class="nav nav-tabs customtab2" role="tablist">
                    <li class="nav-item"> <a class="nav-link active" data-toggle="tab"
                        (click)="order= false; prod = true;"
                        role="tab" aria-selected="false"><span class="hidden-sm-up"></span> <span
                          class="hidden-xs-down">Expired Products</span></a>
                    </li>
                    <li class="nav-item"> <a class="nav-link pointer" data-toggle="tab"
                              (click)="prod= false; order = true"
                              role="tab" aria-selected="false"><span class="hidden-sm-up"></span> <span
                                class="hidden-xs-down">Expired Orders</span></a> </li>

                  </ul>
                  <div class="tab-content">
                    <div class="tab-pane active p-20" *ngIf="!order && prod "
                      role="tabpanel">
                      <h3 class="box-title">Expired Products</h3>
                      <div class="table-responsive">
                        <table class="table table color-bordered-table success-bordered-table">
                          <thead style="font-size: 12px;" class="text-center">
                            <th>SKU </th>
                            <th>Title</th>
                            <th>Expired Date</th>
                          </thead>
                          <tbody style="font-size: 12px;" class="text-center">
                            <tr *ngFor="let prod of allProducts">

                              <td>{{prod.sku}}</td>
                              <td>
                                {{prod.title}}
                              </td>
                              <td>
                                {{prod.expiryDate | date:'dd/MM/yyyy' }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                    </div>
                    <div class=" p-20" *ngIf="!prod "
                      role="tabpanel">
                      <h3 class="box-title">Click and Collect Expired Orders</h3>
                      <div class="table-responsive">
                        <table id="salesTable" class="table table color-bordered-table success-bordered-table table-bordered">
                            <thead style="background: aliceblue;">
                                <tr>
                                    <th style="z-index: 99;">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" id="checkall"
                                                name="checkall" class="custom-control-input checkallP">
                                            <label  class="custom-control-label"
                                                for="checkall"></label>
                                        </div>
                                    </th>
                                    <th >Product Image</th>
                                    <!-- <th style="min-width: 140px;">Order Date <app-sorting></app-sorting>  </th> -->
                                    <th style="min-width: 155px;">Order Id
                                        <!-- <app-filter [multipleColumns]="orderFilters"
                (filterObj)="filterChange($event)" [isMultiple]="'false'"></app-filter> -->
                                    </th>
                                    <th style="min-width: 150px">General </th>
                                    <th style="min-width:150px">Shipping Details
                                    </th>
                                    <th style="min-width:300px">Product
                                        <!-- <app-sorting></app-sorting> &nbsp; <app-filter (filterObj)="filterChange($event)"
                [isMultiple]="'true'" [multipleColumns]="productFilters"></app-filter> -->
                                    </th>
                                    <th
                                        style="min-width: 150px;font-size: 11px;">Qty <br>Item Total
                                    </th>
                                    <!-- <th style="min-width: 110px"> <app-sorting></app-sorting>
            </th> -->
                                    <th style="min-width:150px">Customer</th>
                                    <th style="min-width:215px">Status</th>
                                    <!-- <th>Actions</th> -->
                                </tr>
                            </thead>
                            <tbody class="text-left" style="font-size: 11px;font-weight: 500;">
                                <!-- labelPdfs -->
                                <!-- [ngClass]="sale.selected?'badge-success text-white':''" -->


                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>

                            </tbody>
                        </table>
                      </div>
                    </div>
                    </div>


            </div>
            <!-- <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-success" (click)="phoneSupportSave()">Send
                    Request</button>
                <button type="button" class="btn btn-danger waves-effect" data-dismiss="modal">Close</button>
            </div> -->
        </div>
    </div>
</div>
