<div class="right-sidebar ps ps--theme_default ps--active-y" [ngClass]="isRightSideMenuOpened?'shw-rside':''">
    <div class="slimscrollright">
        <div class="rpanel-title"> Service Panel <span (click)="toggleRightSidemneu()"><i class="ti-close right-side-toggle"></i></span> </div>
        <div class="r-panel-body">
            <ul id="themecolors" class="m-t-20">
                <li><b>Theme Pannel</b></li>
                <li><a (click)="setTheme('skin-default')" [ngClass]="activeTheme=='skin-default'?'working':''" href="javascript:void(0)" data-skin="skin-default" class="default-theme">1</a></li>
                <li><a (click)="setTheme('skin-green')" [ngClass]="activeTheme=='skin-green'?'working':''" href="javascript:void(0)" data-skin="skin-green" class="green-theme">2</a></li>
                <li><a (click)="setTheme('skin-red')" [ngClass]="activeTheme=='skin-red'?'working':''" href="javascript:void(0)" data-skin="skin-red" class="red-theme">3</a></li>
                <li><a (click)="setTheme('skin-blue')" [ngClass]="activeTheme=='skin-blue'?'working':''" href="javascript:void(0)" data-skin="skin-blue" class="blue-theme">4</a></li>
                <li><a (click)="setTheme('skin-purple')" [ngClass]="activeTheme=='skin-purple'?'working':''" href="javascript:void(0)" data-skin="skin-purple" class="purple-theme">5</a></li>
                <li><a (click)="setTheme('skin-megna')" [ngClass]="activeTheme=='skin-megna'?'working':''" href="javascript:void(0)" data-skin="skin-megna" class="megna-theme">6</a></li>
                <li class="d-block m-t-30"><b>With Dark sidebar</b></li>
                <li><a (click)="setTheme('skin-default-dark')" [ngClass]="activeTheme=='skin-default-dark'?'working':''" href="javascript:void(0)" data-skin="skin-default-dark" class="default-dark-theme ">7</a></li>
                <li><a (click)="setTheme('skin-green-dark')" [ngClass]="activeTheme=='skin-green-dark'?'working':''" href="javascript:void(0)" data-skin="skin-green-dark" class="green-dark-theme">8</a></li>
                <li><a (click)="setTheme('skin-red-dark')" [ngClass]="activeTheme=='skin-red-dark'?'working':''" href="javascript:void(0)" data-skin="skin-red-dark" class="red-dark-theme">9</a></li>
                <li><a (click)="setTheme('skin-blue-dark')" [ngClass]="activeTheme=='skin-blue-dark'?'working':''" href="javascript:void(0)" data-skin="skin-blue-dark" class="blue-dark-theme">10</a></li>
                <li><a (click)="setTheme('skin-purple-dark')" [ngClass]="activeTheme=='skin-purple-dark'?'working':''" href="javascript:void(0)" data-skin="skin-purple-dark" class="purple-dark-theme">11</a></li>
                <li><a (click)="setTheme('skin-megna-dark')" [ngClass]="activeTheme=='skin-megna-dark'?'working':''"href="javascript:void(0)" data-skin="skin-megna-dark" class="megna-dark-theme ">12</a></li>
            </ul>
            <!-- <ul class="m-t-20 chatonline">
                <li><b>Chat option</b></li>
                <li>
                    <a href="javascript:void(0)"><img src="../assets/images/users/1.jpg" alt="user-img" class="img-circle"> <span>Varun Dhavan <small class="text-success">online</small></span></a>
                </li>
                <li>
                    <a href="javascript:void(0)"><img src="../assets/images/users/2.jpg" alt="user-img" class="img-circle"> <span>Genelia Deshmukh <small class="text-warning">Away</small></span></a>
                </li>
                <li>
                    <a href="javascript:void(0)"><img src="../assets/images/users/3.jpg" alt="user-img" class="img-circle"> <span>Ritesh Deshmukh <small class="text-danger">Busy</small></span></a>
                </li>
                <li>
                    <a href="javascript:void(0)"><img src="../assets/images/users/4.jpg" alt="user-img" class="img-circle"> <span>Arijit Sinh <small class="text-muted">Offline</small></span></a>
                </li>
                <li>
                    <a href="javascript:void(0)"><img src="../assets/images/users/5.jpg" alt="user-img" class="img-circle"> <span>Govinda Star <small class="text-success">online</small></span></a>
                </li>
                <li>
                    <a href="javascript:void(0)"><img src="../assets/images/users/6.jpg" alt="user-img" class="img-circle"> <span>John Abraham<small class="text-success">online</small></span></a>
                </li>
                <li>
                    <a href="javascript:void(0)"><img src="../assets/images/users/7.jpg" alt="user-img" class="img-circle"> <span>Hritik Roshan<small class="text-success">online</small></span></a>
                </li>
                <li>
                    <a href="javascript:void(0)"><img src="../assets/images/users/8.jpg" alt="user-img" class="img-circle"> <span>Pwandeep rajan <small class="text-success">online</small></span></a>
                </li>
            </ul> -->
        </div>
    </div>
</div>
