// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: true,
  defaultPaginationValue: 25,
  defaultTotalValue: 1,
  numberOfTabs: 5,
  maxNumberOfTabsAllowed: 8,
  ui_base_url: "https://login.sellershub.io/secure/sellershub/",
  // Report
  repo_getReportByProduct: "https://login.sellershub.io/findsaleproductreport",
  activateUser: "https://login.sellershub.io/activateUser",
  acceptebayresttoken: "https://login.sellershub.io/acceptebayresttoken",
  repo_lowStockReports: "https://login.sellershub.io/findlowstockproductreport",
  repo_getStockValueReport: "https://login.sellershub.io/findstockvaluereport",
  repo_getReportorderhistory: "https://login.sellershub.io/orderhistory",
  repo_getStockValueOrder: "https://login.sellershub.io/stockvaluereport",
  repo_export: "https://login.sellershub.io/new/exportOrders",
  // Inventory
  inv_getProducts: "https://login.sellershub.io/rest/dynamicSearch",
  inv_getChannelproducts:
    "https://login.sellershub.io/rest/findAllChannelProducts",
  inv_getStockview: "https://login.sellershub.io/rest/stockitemsview",
  removeProduct: "https://login.sellershub.io/rest/removekartzhubproducts",
  create_bundle_product: "https://login.sellershub.io/rest/createbundleproduct",
  update_bundle_product: "https://login.sellershub.io/rest/updatebundleproduct",
  link_product: "https://login.sellershub.io/rest/linkproducts",
  update_tracking: "https://login.sellershub.io/rest/updateTracking",
  unlink_product: "https://login.sellershub.io/rest/unlinkproducts",
  confirem_link_products:
    "https://login.sellershub.io/rest/confirmlinkproducts",
  confirem_unlink_products:
    "https://login.sellershub.io/rest/confirmlinkproducts",
  excluseSKUFrominven: "https://login.sellershub.io/rest/skuWiseInventorySync",
  startAddToChannel: "https://login.sellershub.io/rest/startaddtochannel",
  addToChannel: "https://login.sellershub.io/rest/addtochannel",
  getCurrencyCodes: "https://login.sellershub.io/rest/gettocurrencyvalue",
  updateChannelProductQtyForSku:
    "https://login.sellershub.io/rest/updateChannelProductQtyForSku",
  update_bulk_revise_price: "https://login.sellershub.io/rest/updateprice",
  view_order_details: "https://login.sellershub.io/rest/viewOrderDetails",
  view_kartzhub_products:
    "https://login.sellershub.io/channelintegration/viewKartzhubProducts",
  view_channel_products:
    "https://login.sellershub.io/channelintegration/viewChannelProducts",
  createMapLinkSku:
    "https://login.sellershub.io/channelintegration/createMapLinkSku",

  // Warehouse
  update_transfer: "https://login.sellershub.io/updatestocktransfer",
  ware_getWarehouse: "https://login.sellershub.io/warehouse/warehouse",
  ware_getStocksummary: "https://login.sellershub.io/warehouse/stockitemsview",
  ware_getTransfer: "https://login.sellershub.io/warehouse/stocktransferitem",
  ware_removewarehouse: "https://login.sellershub.io/warehouse/removewarehouse",
  ware_removewarehouse_location:
    "https://login.sellershub.io/warehouse/removewarehouselocation",
  ware_editwarehouse: "https://login.sellershub.io/warehouse/editwarehouse",
  ware_updatewarehouse: "https://login.sellershub.io/warehouse/updatewarehouse",
  ware_findwarehouselocations:
    "https://login.sellershub.io/warehouse/findwarehouselocations",
  ware_editwarehouselocation:
    "https://login.sellershub.io/warehouse/editwarehouselocation",
  ware_updatewarehouselocation:
    "https://login.sellershub.io/warehouse/updatewarehouselocation",
  ware_getStock: "https://login.sellershub.io/rest/stockitem",
  ware_findcurrentlocationsforsku:
    "https://login.sellershub.io/findcurrentlocationsforsku",
  ware_findcurrentlocationsforskutransfer:
    "https://login.sellershub.io/findcurrentlocationsforskutransfer",
  ware_updatestockforlocations:
    "https://login.sellershub.io/updatestockforlocations",
  ware_updatestocktransfer: "https://login.sellershub.io/updatestocktransfer",
  ware_productNew: "https://login.sellershub.io/productnew",
  ware_assignlocationforProduct:
    "https://login.sellershub.io/assignlocationforproducts",
  ware_showProduct: "https://login.sellershub.io/showproductswarehouselocation",

  //Orders
  order_getOrderSummary: "https://login.sellershub.io/new/orderspagesummary",
  order_getSalesOrders: "https://login.sellershub.io/new/dynamicSearchPerformance",
  dynamicSearchOrders: "https://login.sellershub.io/new/dynamicSearchOrders",
  order_getShippedOrdersSearch: "https://login.sellershub.io/new/shippedorders",
  order_getMcfOrders: "https://login.sellershub.io/new/viewfbaorders",
  order_findMcfOrders: "https://login.sellershub.io/new/findfbaorders",
  order_getCancelledOrders:
    "https://login.sellershub.io/new/getcancelledorderslist",
  order_getReturnedOrderspara:
    "https://login.sellershub.io/new/returnedordersdata",
  order_notes_list: "https://login.sellershub.io/new/displayComments",
  order_notes_create: "https://login.sellershub.io/new/loadordercomments",
  cancelorder: "https://login.sellershub.io/new/cancelorder",
  //sales Order Sub
  downloadordersfromchannels:
    "https://login.sellershub.io/new/downloadordersfromchannels",
  applydownloadordersfromchannelid:
    "https://login.sellershub.io/new/applydownloadordersfromchannelid",
  orderDetailsBasedonOrderID: "https://login.sellershub.io/new/searchOrderId",
  searchOrderTrackingId:
    "https://login.sellershub.io/new/searchOrderTrackingId",
  confirmorderpayment: "https://login.sellershub.io/new/confirmorderpayment",
  bulkemail: "https://login.sellershub.io/new/bulkemail",
  orderProcess: "https://login.sellershub.io/new/orderProcess",
  findOrdercancel: "https://login.sellershub.io/new/findordersforcancel",
  findOrdercancelv2: "https://login.sellershub.io/orderutility/orderitems",
  createPickList: "https://login.sellershub.io/new/exportpicklist",
  createPackList: "https://login.sellershub.io/new/exportpacklist",
  emailGetInfo: "https://login.sellershub.io/new/bulkemail",
  mergeOrder: "https://login.sellershub.io/orderutility/merge",
  unmergeOrder: "orderutility/undomerge",
  splitOrder_Orderitems: "https://login.sellershub.io/orderutility/orderitems",
  splitOrder: "https://login.sellershub.io/orderutility/split",
  undoSplitOrder: "https://login.sellershub.io/orderutility/undosplit",
  print_lablesMenus: "https://login.sellershub.io/shippingcourier/all",
  labelDetails: "https://login.sellershub.io/new/loadcuriershippingservices",
  confirm_shipment: "https://login.sellershub.io/new/confirmshipment",
  findOrdersForShipping:
    "https://login.sellershub.io/new/findordersforshipment",
  finddraftorders: "https://login.sellershub.io/new/finddraftorders",
  removeOrdersFromDraft: "https://login.sellershub.io/new/removeorder",
  productnew: "https://login.sellershub.io/productnew",
  createOrder: "https://login.sellershub.io/new/createorder",
  updateSaleItem: "https://login.sellershub.io/new/updatesaleitem",
  removeProductSaleItem:
    "https://login.sellershub.io/new/removeproductfromorder",
  saveOrder: "https://login.sellershub.io/new/saveorder",
  addProductToOrder: "https://login.sellershub.io/new/addproducttoorder",
  editOrderDetails: "https://login.sellershub.io/new/editeachorderitem",
  expired_products: "https://login.sellershub.io/findexpireProductdate",
  // sendEmailConfirm:'https://login.sellershub.io/new/sendemailconfirm',
  sendEmailConfirm: "https://login.sellershub.io/new/sendbulkemailconfirm",
  printInvoice: "https://login.sellershub.io/new/bulkprintinvoices",
  exportOrdertoCsv: "https://login.sellershub.io/orderutility/orders",
  //exports
  exportSaleProductReport:
    "https://login.sellershub.io/new/exportSaleProductReport",
  exportStockValueReport:
    "https://login.sellershub.io/new/exportStockValueReport",
  exportLowStockReport: "https://login.sellershub.io/new/exportLowStockReport",
  exportOrderReport: "https://login.sellershub.io/new/exportOrderReport",
  exportOrderHistoryReport:
    "https://login.sellershub.io/new/exportOrderHistoryReport",
  exportChannelProductsReport:
    "https://login.sellershub.io/new/exportChannelProductsReport",
  exportPickingListReport:
    "https://login.sellershub.io/new/exportPickingListReport",
  exportPackingListReport:
    "https://login.sellershub.io/new/exportPackingListReport",
  //Dashboard API's
  das_dashboardnew: "https://login.sellershub.io/dashboardnew",
  dash_ordersummary: "https://login.sellershub.io/ordersummary",
  dash_loadsalesbychannel: "https://login.sellershub.io/loadsalesbychannel",
  dash_inventorysummary: "https://login.sellershub.io/inventorysummary",
  dash_loadsalesbycountry: "https://login.sellershub.io/loadsalesbycountry",
  dash_loadorderhistory: "https://login.sellershub.io/loadorderhistory",
  //Messages In headers
  msg_listmsg: "https://login.sellershub.io/messages",
  msg_replymsg: "https://login.sellershub.io/replytomessage",
  msg_replySavemsg: "https://login.sellershub.io/savereplymessage",
  msg_showmsg: "https://login.sellershub.io/showmessagedetails",

  //Customers
  cust_customerlist: "https://login.sellershub.io/Customers/list",
  savecustomer: "https://login.sellershub.io/Customers/savecustomer",
  find_searchname: "https://login.sellershub.io/Customers/findSearchName",
  BASE_URL: "https://login.sellershub.io",
  user_profile: "https://login.sellershub.io/user",
  //Listing
  list_waitingtolist:
    "https://login.sellershub.io/listing/findproductwaitingtolistData",
  list_listingerrors:
    "https://login.sellershub.io/listing/findproductlistingerrorsdata",
  list_listedproduct: "https://login.sellershub.io/listing/listedproducts",
  list_closedproduct:
    "https://login.sellershub.io/listing/findproductcloseditemsdata",
  list_schedulelisting:
    "https://login.sellershub.io/listing/findscheduledlistings",
  list_createproductstofba:
    "https://login.sellershub.io/listing/createfbashipment",
  list_sendproducttofba: "https://login.sellershub.io/listing/listshipment",
  // list_productstofba: 'https://login.sellershub.io/listing/findfulfilmentproducts',
  list_productstofba:
    "https://login.sellershub.io/listing/findfulfilmentproductssearch",
  list_selectlistingtemplate:
    "https://login.sellershub.io/listing/selectlistingtemplateupdatechannel",
  list_updatetemplate:
    "https://login.sellershub.io/listing/updatelistingtemplateforchannelproducts",
  list_updateChanneltemplate:
    "https://login.sellershub.io/listing/updateChannel",
  list_removeerrorlist: "https://login.sellershub.io/listing/removeerrorlist",
  list_removewaitinglist:
    "https://login.sellershub.io/listing/removewaitinglist",
  list_shipmentitems: "https://login.sellershub.io/listing/listshipmentitems",
  list_newinboundshipment:
    "https://login.sellershub.io/listing/newinboundshipment",
  // Suppliers
  supl_supplier: "https://login.sellershub.io/new/suppliers",
  supl_feed: "https://login.sellershub.io/feed",
  supl_remove: "https://login.sellershub.io/new/removesupplier",
  supl_remove_product: "https://login.sellershub.io/new/removesupplierproduct",
  supl_add: "https://login.sellershub.io/new/updatesupplier",
  supl_edit: "https://login.sellershub.io/new/editsupplier",
  save_product_suppliers:
    "https://login.sellershub.io/new/saveproductSupplierslist",
  get_prod_supplier:
    "https://login.sellershub.io/new/findcurrentsuppliersforsku",

  //Integrations
  inte_shippingCourier: "https://login.sellershub.io/shippingcourier/all",
  delete_ShippingCarrier:
    "https://login.sellershub.io/shippingcourier/deleteShippingCarrier",
  inte_channelRegistration: "https://login.sellershub.io/channeluser",
  add_shippingmethod:
    "https://login.sellershub.io/new/updateordershippingmethod",
  inte_ebaygenerateTokenNew:
    "https://login.sellershub.io/channelintegration/ebaytoken",
  inte_ebaygenerateToken:
    "https://login.sellershub.io/channelintegration/ebay/ebaytoken",
  inte_ebaytestCon:
    "https://login.sellershub.io/channelintegration/ebay/testconnection",
  inte_ebaydownloadAllProduct:
    "https://login.sellershub.io/channelintegration/ebay/downloadallproducts",
  inte_ebaydownloadrecentProduct:
    "https://login.sellershub.io/channelintegration/ebay/downloadrecentproduct",
  inte_ebay: "https://login.sellershub.io/channelintegration/ebay",
  findebayitemspecificsfortemplate:
    "https://login.sellershub.io/findebayitemspecificsfortemplate",
  findebayitemspecifics: "https://login.sellershub.io/findebayitemspecifics",
  amazon_subcategories: "https://login.sellershub.io/findamazonsubcategories",
  amazon_browsernodes: "https://login.sellershub.io/searchamazonbrowsernodes",
  amazon_existing_data: "https://login.sellershub.io/channelintegration/amazon",
  amazon_test_connection:
    "https://login.sellershub.io/channelintegration/amazon/testconnection",
  amazon_download_recent_prod:
    "https://login.sellershub.io/channelintegration/amazon/downloadrecentproducts",
  amazon_downloadAllProducts:
    "https://login.sellershub.io/channelintegration/amazon/downloadallproducts",
  get_all_stores: "https://login.sellershub.io/clickandcollect/getAllStores",
  get_storesById: "https://login.sellershub.io/clickandcollect/getStoreById",
  create_stores: "https://login.sellershub.io/clickandcollect/createStore",
  update_stores: "https://login.sellershub.io/clickandcollect/updateStore",
  delete_stores: "https://login.sellershub.io/clickandcollect/archiveStore",
  update_store_status:
    "https://login.sellershub.io/clickandcollect/updateStoreStatus",
  update_shipstatus:
    "https://login.sellershub.io/clickandcollect/updateClickAndCollectShipmentStatus",
  find_all_orders:
    "https://login.sellershub.io/clickandcollect/findAllISPUOrders",
  inventory_update:
    "https://login.sellershub.io/clickandcollect/cncInventoryUpdate",
  shopify_existingData:
    "https://login.sellershub.io/channelintegration/shopify",
  walmart_existingData:
    "https://login.sellershub.io/channelintegration/walmart",
  walmart_downloadRecent_prod:
    "https://login.sellershub.io/channelintegration/amazon/downloadrecentproducts",
  shopwired_existingData:
    "https://login.sellershub.io/channelintegration/shopwired",
  bigcommerce_existingData:
    "https://login.sellershub.io/channelintegration/bigcommerce",
  kashflow_existingData: "https://login.sellershub.io/accounting/savekashflow",
  shopify_testConnection:
    "https://login.sellershub.io/channelintegration/shopify/testconnection",
  walmart_testConnection:
    "https://login.sellershub.io/channelintegration/walmart/testconnection",
  shopwired_testConnection:
    "https://login.sellershub.io/channelintegration/shopwired/testconnection",
  bigcommerce_testConnection:
    "https://login.sellershub.io/channelintegration/bigcommerce/testconnection",
  shopify_downloadRecent_prod:
    "https://login.sellershub.io/channelintegration/amazon/downloadrecentproducts",
  shopify_download_all_prod:
    "https://login.sellershub.io/channelintegration/shopify/downloadallproducts",
  walmart_download_all_prod:
    "https://login.sellershub.io/channelintegration/walmart/downloadallproducts",
  shopwired_download_all_prod:
    "https://login.sellershub.io/channelintegration/shopwired/downloadallproducts",
  bigcommerce_download_all_prod:
    "https://login.sellershub.io/channelintegration/bigcommerce/downloadallproducts",
  prestashop_existingData:
    "https://login.sellershub.io/channelintegration/prestashop",
  prestashop_testConnection:
    "https://login.sellershub.io/channelintegration/prestashop/testconnection",
  prestashop_downloadRecent_prod:
    "https://login.sellershub.io/channelintegration/amazon/downloadrecentproducts",
  prestashop_download_all_prod:
    "https://login.sellershub.io/channelintegration/prestashop/downloadallproducts",
  woocommerce_existingData:
    "https://login.sellershub.io/channelintegration/woocommerce",
  woocommerce_testConnection:
    "https://login.sellershub.io/channelintegration/woocommerce/testconnection",
  woocommerce_downloadRecent_prod:
    "https://login.sellershub.io/channelintegration/amazon/downloadrecentproducts",
  woocommerce_download_all_prod:
    "https://login.sellershub.io/channelintegration/woocommerce/downloadallproducts",
  magento2_existingData:
    "https://login.sellershub.io/channelintegration/magento2",
  magento2_testConnection:
    "https://login.sellershub.io/channelintegration/magento2/testconnection",
  magento2_downloadRecent_prod:
    "https://login.sellershub.io/channelintegration/amazon/downloadrecentproducts",
  magento2_download_all_prod:
    "https://login.sellershub.io/channelintegration/magento2/downloadallproducts",
  onbuy_existingData: "https://login.sellershub.io/channelintegration/onbuy",
  onbuy_testConnection:
    "https://login.sellershub.io/channelintegration/onbuy/testconnection",
  onbuy_downloadRecent_prod:
    "https://login.sellershub.io/channelintegration/onbuy/downloadrecentproducts",
  onbuy_download_all_prod:
    "https://login.sellershub.io/channelintegration/onbuy/downloadallproducts",
  onbuy_tocken:
    "https://login.sellershub.io/channelintegration/generateonbuytoken",
  etsy_existingData: "https://login.sellershub.io/channelintegration/etsy",
  etsy_testConnection:
    "https://login.sellershub.io/channelintegration/etsy/testconnection",
  etsy_downloadRecent_prod:
    "https://login.sellershub.io/channelintegration/etsy/downloadrecentproducts",
  etsy_download_all_prod:
    "https://login.sellershub.io/channelintegration/etsy/downloadallproducts",
  etsy_tocken: "https://login.sellershub.io/channelintegration/generateetsytoken",
  sears_existingData: "https://login.sellershub.io/channelintegration/sears",
  sears_testConnection:
    "https://login.sellershub.io/channelintegration/sears/testconnection",
  sears_downloadRecent_prod:
    "https://login.sellershub.io/channelintegration/sears/downloadrecentproducts",
  sears_download_all_prod:
    "https://login.sellershub.io/channelintegration/sears/downloadallproducts",
  cdiscount_existingData:
    "https://login.sellershub.io/channelintegration/cdiscount",
  cdiscount_testConnection:
    "https://login.sellershub.io/channelintegration/cdiscount/testconnection",
  cdiscount_downloadRecent_prod:
    "https://login.sellershub.io/channelintegration/cdiscount/downloadrecentproducts",
  cdiscount_download_all_prod:
    "https://login.sellershub.io/channelintegration/cdiscount/downloadallproducts",
  priceminister_existingData:
    "https://login.sellershub.io/channelintegration/priceminister",
  priceminister_testConnection:
    "https://login.sellershub.io/channelintegration/priceminister/testconnection",
  priceminister_downloadRecent_prod:
    "https://login.sellershub.io/channelintegration/priceminister/downloadrecentproducts",
  priceminister_download_all_prod:
    "https://login.sellershub.io/channelintegration/priceminister/downloadallproducts",
  lazada_existingData: "https://login.sellershub.io/channelintegration/lazada",
  lazada_testConnection:
    "https://login.sellershub.io/channelintegration/lazada/testconnection",
  lazada_downloadRecent_prod:
    "https://login.sellershub.io/channelintegration/lazada/downloadrecentproducts",
  lazada_download_all_prod:
    "https://login.sellershub.io/channelintegration/lazada/downloadallproducts",
  lazada_tocken:
    "https://login.sellershub.io/channelintegration/generatelazadatoken",
  bonanza_existingData:
    "https://login.sellershub.io/channelintegration/bonanza",
  bonanza_testConnection:
    "https://login.sellershub.io/channelintegration/bonanza/testconnection",
  bonanza_downloadRecent_prod:
    "https://login.sellershub.io/channelintegration/bonanza/downloadrecentproducts",
  bonanza_download_all_prod:
    "https://login.sellershub.io/channelintegration/bonanza/downloadallproducts",
  bonanza_tocken: "https://login.sellershub.io/generatebonanzatoken",
  shopee_existingData: "https://login.sellershub.io/channelintegration/shopee",
  shopee_testConnection:
    "https://login.sellershub.io/channelintegration/shopee/testconnection",
  shopee_downloadRecent_prod:
    "https://login.sellershub.io/channelintegration/shopee/downloadrecentproducts",
  shopee_download_all_prod:
    "https://login.sellershub.io/channelintegration/shopee/downloadallproducts",
  opencart_existingData:
    "https://login.sellershub.io/channelintegration/opencart",
  opencart_testConnection:
    "https://login.sellershub.io/channelintegration/opencart/testconnection",
  opencart_downloadRecent_prod:
    "https://login.sellershub.io/channelintegration/opencart/downloadrecentproducts",
  opencart_download_all_prod:
    "https://login.sellershub.io/channelintegration/opencart/downloadallproducts",
  magento_existingData:
    "https://login.sellershub.io/channelintegration/magento",
  magento_testConnection:
    "https://login.sellershub.io/channelintegration/magento/testconnection",
  magento_downloadRecent_prod:
    "https://login.sellershub.io/channelintegration/magento/downloadrecentproducts",
  magento_download_all_prod:
    "https://login.sellershub.io/channelintegration/magento/downloadallproducts",
  volusion_existingData:
    "https://login.sellershub.io/channelintegration/volusion",
  volusion_testConnection:
    "https://login.sellershub.io/channelintegration/volusion/testconnection",
  volusion_downloadRecent_prod:
    "https://login.sellershub.io/channelintegration/volusion/downloadrecentproducts",
  volusion_download_all_prod:
    "https://login.sellershub.io/channelintegration/volusion/downloadallproducts",
  bluepark_existingData:
    "https://login.sellershub.io/channelintegration/bluepark",
  bluepark_testConnection:
    "https://login.sellershub.io/channelintegration/bluepark/testconnection",
  bluepark_downloadRecent_prod:
    "https://login.sellershub.io/channelintegration/bluepark/downloadrecentproducts",
  bluepark_download_all_prod:
    "https://login.sellershub.io/channelintegration/bluepark/downloadallproducts",
  xcart_existingData: "https://login.sellershub.io/channelintegration/xcart",
  xcart_testConnection:
    "https://login.sellershub.io/channelintegration/xcart/testconnection",
  xcart_downloadRecent_prod:
    "https://login.sellershub.io/channelintegration/xcart/downloadrecentproducts",
  xcart_download_all_prod:
    "https://login.sellershub.io/channelintegration/xcart/downloadallproducts",
  connect_to_xero: "https://login.sellershub.io/xerorequest2",
  enable_accounting: "https://login.sellershub.io/enableaccounting",
  index: "https://login.sellershub.io/index",
  xero_testconnection: "https://login.sellershub.io/testconnection",
  edit_accounting: "https://login.sellershub.io/editaccount",
  enablechannelforaccounting:
    "https://login.sellershub.io/enablechannelforaccounting",
  uploadtoxero: "https://login.sellershub.io/uploadtoxero",
  savexero: "https://login.sellershub.io/savexero",
  download_orders_from_xero:
    "https://login.sellershub.io/downloadvaluesfromxero",
  //User Management
  user_manageUser: "https://login.sellershub.io/user",
  //Registration
  reg_registration: "https://login.sellershub.io/registernew",
  //Settings
  get_printers: "https://login.sellershub.io/rest/getPrinters",
  set_generalsettings: "https://login.sellershub.io/generalsetting",
  set_unusedbarcode: "https://login.sellershub.io/findunusedbarcodes",
  set_usedbarcode: "https://login.sellershub.io/findbarcodes",
  set_skubarcode: "https://login.sellershub.io/findconvertskutobarcodes",
  set_bulkaction: "https://login.sellershub.io/exportfile",
  set_removebulkimport: "https://login.sellershub.io/removeimportfile",
  set_savedefault: "https://login.sellershub.io/savedefaultsettings",
  set_emailSetting: "https://login.sellershub.io/emailsetting",
  set_testmailcon: "https://login.sellershub.io/testsmtpconnection",
  set_savemailcon: "https://login.sellershub.io/saveemtpdetails",
  set_notification: "https://login.sellershub.io/loadnotifcations",
  set_syncchannel: "https://login.sellershub.io/synchronizesettings",
  set_shippingrulrs: "https://login.sellershub.io/shippingrules",
  set_saveattribute: "https://login.sellershub.io/saveattribute",
  set_saveattributeset: "https://login.sellershub.io/saveattributeset",
  set_saveattributesetValue:
    "https://login.sellershub.io/saveattributevaluestoset",
  set_editattribute: "https://login.sellershub.io/editattribute",
  set_editattributeset: "https://login.sellershub.io/editattributeset",
  set_removeattribute: "https://login.sellershub.io/removeattribute",
  set_removeattributeset: "https://login.sellershub.io/removeattributeset",
  set_removeexport: "https://login.sellershub.io/removeexportfile",
  set_removeimport: "https://login.sellershub.io/removeimportfile",
  set_removeshippingrule: "https://login.sellershub.io/removeshippingrules",
  set_removesmtp: "https://login.sellershub.io/removesmptconnection",
  set_synchronizesku: "https://login.sellershub.io/removesynchronizesku",
  set_bulkimportfile: "https://login.sellershub.io/bulkimportfile",
  set_edittaxrate: "https://login.sellershub.io/edittaxrate",
  set_savetaxrate: "https://login.sellershub.io/updatetaxrate",
  set_savecurrency: "https://login.sellershub.io/savegeneraldetailcurrency",
  set_customizepacklist: "https://login.sellershub.io/customizepacklist",
  set_customizepicklist: "https://login.sellershub.io/customizepicklist",
  set_removetaxrate: "https://login.sellershub.io/removetaxrate",
  set_addunusedbarcode: "https://login.sellershub.io/updateautoadd",
  set_updatepicklist: "https://login.sellershub.io/updatecustompicklist",
  set_updatepacklist: "https://login.sellershub.io/updatecustompacklist",
  set_savesyncsettings: "https://login.sellershub.io/savesynchronizesettings",
  set_savelowstock: "https://login.sellershub.io/savelowstockalertemail",
  set_saveneworderemail: "https://login.sellershub.io/saveneworderemailseller",
  set_savenewordersms: "https://login.sellershub.io/savenewordersmsseller",
  set_saveshippingrules: "https://login.sellershub.io/saveshippingrules",
  set_editshippingrules: "https://login.sellershub.io/editshippingrules",
  delete_integration: "https://login.sellershub.io/channelintegration",
  updateSplitRules:
    "https://login.sellershub.io/channelintegration/updateSplitRules",
  user_subscription: "https://login.sellershub.io/subscriptions",
  user_priceplans: "https://login.sellershub.io/priceplans",
  user_add: "https://login.sellershub.io/savegeneraldetails",
  label_setup: "https://login.sellershub.io/shippingcourier/labelsetup",
  user_edit: "https://login.sellershub.io/edituser",
  user_update: "https://login.sellershub.io/updateuser",
  user_remove: "https://login.sellershub.io/removeuser",
  user_accountSettings: "https://login.sellershub.io/myaccountsettings",
  invoice_printed: "https://login.sellershub.io/new/invoiceprinted",
  label_printed: "https://login.sellershub.io/new/labelprinted",
  generate_printLabel:
    "https://login.sellershub.io/new/generateintegratedlabels",
  get_printLabel: "http://login.sellershub.io/new/labelprinted",
  create_product: "https://login.sellershub.io/rest/createproduct",
  edit_product: "https://login.sellershub.io/rest/editproduct",
  find_supplierbysku: "https://login.sellershub.io/new/findsupplierbysku",
  edit_channel_product: "https://login.sellershub.io/rest/editchannelproduct",
  save_general: "https://login.sellershub.io/rest/khubproductsavegeneral",
  save_ku_details: "https://login.sellershub.io/rest/khubproductsaveskudetails",
  save_variations: "https://login.sellershub.io/rest/saveproductvariationlist",
  upload_image: "https://login.sellershub.io/rest/productUploadImages",
  saveAttributes: "https://login.sellershub.io/rest/saveProductAttributes",
  find_images_based_on_product:
    "https://login.sellershub.io/rest/findProductImages",
  user_editaccountsubuserpermissions:
    "https://login.sellershub.io/editaccountsubuserpermissions",
  user_savepermissions: "https://login.sellershub.io/savepermissions",
  remove_image: "https://login.sellershub.io/rest/removeproductimages",
  get_purchase_orders: "https://login.sellershub.io/rest/getpurchaseorderslist",
  removesynchronizesku: "https://login.sellershub.io/removesynchronizesku",
  invoice_templates: "https://login.sellershub.io/invoicetemplates",
  edit_invoice: "https://login.sellershub.io/editinvoicetemplate",
  updateordershipping: "https://login.sellershub.io/new/updateordershipping",
  updatePaymentMethodForOrder:
    "https://login.sellershub.io/new/updatePaymentMethodForOrder",
  update_qty: "https://login.sellershub.io/rest/updateqtyforsku",
  update_qty1: "https://login.sellershub.io/rest/updateqtyforsku1",
  createTicket: "https://login.sellershub.io/createticket",
  callback: "https://login.sellershub.io/callback",
  merge_product_list: "https://login.sellershub.io/rest/mergeproducts",
  start_merge_prod: "https://login.sellershub.io/rest/startmergeproduct",
  duplicate_product: "https://login.sellershub.io/rest/duplicateproduct",
  print_product: "https://login.sellershub.io/rest/printproduct",
  delete_product: "https://login.sellershub.io/rest/removeproduct",
  findDetials_in_channel_products:
    "https://login.sellershub.io/rest/findqtyforsku",
  find_warehouselocationsforproducts:
    "https://login.sellershub.io/warehouse/findwarehouselocationsforproducts",
  saveImages_inproduct: "https://login.sellershub.io/rest/saveproductimages",
  imagesForMapping: "https://login.sellershub.io/rest/findproductimagemappings",
  save_child_images: "https://login.sellershub.io/rest/savechildimages",

  // ebay
  listingtemplate: "https://login.sellershub.io/channellistingtemplate",
  // etsy
  getetsytemplate: "",
  delete_po: "https://login.sellershub.io/rest/deletepurchaseorderheader",
  display_supplier_products:
    "https://login.sellershub.io/rest/displaySupplierProducts",
  edit_po: "https://login.sellershub.io/rest/editpurchaseorder",
  save_po: "https://login.sellershub.io/rest/savepurchaseorder",
  save_po_header: "https://login.sellershub.io/rest/savepurchaseorderheader",
  print_po: "https://login.sellershub.io/rest/printpurchaseorder",
  email_po: "https://login.sellershub.io/new/emailpurchaseorder",
  save_template: "https://login.sellershub.io/saveinvoicetemplate",
  verify_bundle: "https://login.sellershub.io/rest/validateProductsNotBundled",
  list_now: "https://login.sellershub.io/rest/updatetochannel",
  updatetochannel: "https://login.sellershub.io/channel/updatetochannel",
  removechannelproductOnly:
    "https://login.sellershub.io/rest/removechannelproductOnly",
  schedule_listing: "https://login.sellershub.io/rest/schedulelistng",
  invoice_print_path:
    "https://login.sellershub.io/secure/sellershub/printinvoice",
  label_print_path: "https://login.sellershub.io/secure/sellershub/printlabels",
  create_po: "https://login.sellershub.io/rest/createpurchaseorder",
  bundle_prods_modal: "https://login.sellershub.io/rest/startsearchaddtobundle",
  find_bundle_prods: "https://login.sellershub.io/rest/findbundleproduts",
  update_save_bundle_chaild_items:
    "https://login.sellershub.io/rest/updatesavebundlechilditems",
  add_bundle: "https://login.sellershub.io/rest/addtobundle",
  remove_bundle_prod:
    "https://login.sellershub.io/rest/removekartzhubproductfromgroup",
  findebaycategories: "https://login.sellershub.io/findebaycategories",
  loadebaycategories: "https://login.sellershub.io/loadebaycategories",
  loadebaystorecategories:
    "https://login.sellershub.io/loadebaystorecategories",
  downloadebaylatestpolices:
    "https://login.sellershub.io/downloadebaylatestpolices",
  loadetsycategories: "https://login.sellershub.io/loadetsycategories",
  loadonbuycategories:
    "https://login.sellershub.io/channelintegration/onbuy/loadonbuycategories",
  searchonbuycategories:
    "https://login.sellershub.io/channelintegration/onbuy/loadonbuycategory",
  loadshopifystorecategories:
    "https://login.sellershub.io/loadshopifystorecategories",
  enable_equal_qty: "https://login.sellershub.io/rest/enableEditQty",
  deleteSubscrption: "https://login.sellershub.io/deleteAccountRequest",
  saveshippinglabel: "https://login.sellershub.io/saveshippinglabel",
  savechannelproduct: "https://login.sellershub.io/rest/savechannelproduct",
  removewaitinglist: "https://login.sellershub.io/rest/removewaitinglist",
  cancel_label: "https://login.sellershub.io/new/cancelamazonshipping",
  after_cancel_label: "https://login.sellershub.io/new/labelNotprinted",
  editshippinglabel: "https://login.sellershub.io/editshippinglabel",
  resetPassword: "https://login.sellershub.io/submitforgotuserdetailsapi",
  uploadImagesFromurl: "https://login.sellershub.io/rest/uploadimagesfromurl",

  stockRepricerSettings:
    "https://login.sellershub.io/rest/stockrepricer/updatepricerules",
  stockRepricerRules:
    "https://login.sellershub.io/rest/stockrepricer/findamazonrepricerules",
  stockfindAllItems:
    "https://login.sellershub.io/rest/stockrepricer/findallitems",
  findStockRepriceItems:
    "https://login.sellershub.io/rest/stockrepricer/findstockrepriceritems",
  findNotReseterItems:
    "https://login.sellershub.io/rest/stockrepricer/findnotresetitems",
  savepurchaseorderlines:
    "https://login.sellershub.io/rest/savepurchaseorderlines",
  paymentmethods: "https://login.sellershub.io/paymentmethods",
  submitPayment: "https://login.sellershub.io/submitpayment",

  emailconfig: "https://login.sellershub.io/emailconfig",
  saveemtpconfig: "https://login.sellershub.io/saveemtpconfig",
  testsmtpconfig: "https://login.sellershub.io/testsmtpconfig",
  removesmtpconfig: "https://login.sellershub.io/removesmtpconfig",
  activatesmtpconfig: "https://login.sellershub.io/activatesmtpconfig",
  po_email: "https://login.sellershub.io/new/sendpurchaseorderemail",
  savetemplateproductattributes:
    "https://login.sellershub.io/new/savetemplateproductattributes",
  productcategory: "https://login.sellershub.io/productcategory",
  create_productcategory: "https://login.sellershub.io/productcategory",
  delete_productcategory: "https://login.sellershub.io/productcategory/delete",
  findskulike: "https://login.sellershub.io/findskulike",
  inverntory_trcking: "https://login.sellershub.io/inventorytrack/transaction",
  findSupplierForProduct: "https://login.sellershub.io/new/suppliers/info",
  passwordResetSubmit: "https://login.sellershub.io/passwordreset/submit",

  deletepurchaseorderline:
    "https://login.sellershub.io/new/deletepurchaseorderline",
  pdftoblob: "https://login.sellershub.io/new/blobs",
  s3Image: "https://login.sellershub.io/new/s3Image",
  pdftoblobDuplex: "https://login.sellershub.io/new/duplexPrint",
  composition: "https://login.sellershub.io/rest/composition",
  barcode: "https://login.sellershub.io/rest/barcode2",
  etsycallback: "https://login.sellershub.io/etsycallback",
  amzbuyshippingmethods:
    "https://login.sellershub.io/new/amzbuyshippingmethods",
  find_product_attributes: "https://login.sellershub.io/findchannelattributes",
  shippingcourier_template:
    "https://login.sellershub.io/shippingcourier/template",
  shipping_manifests: "https://login.sellershub.io/new/carrierCount",
  view_shipping_manifests: "https://login.sellershub.io/new/filterSearch",
  generate_manifest: "https://login.sellershub.io/new/manifestIdGeneration",
  retrieve_manifestId: "https://login.sellershub.io/new/retrieveManifestId",
  export_manifest: "https://login.sellershub.io/new/exportManifestDetails",
  retrieve_allmanifestIds:
    "https://login.sellershub.io/new/retrieveAllManifestIds",
  /* FBA */
  fba_plans: "https://login.sellershub.io/fba/plans",
  fba_prepInstructions: "https://login.sellershub.io/fba/prepInstructions",
  fba_create_inbound: "https://login.sellershub.io/fba/createInbound",
  fba_update_inbound: "https://login.sellershub.io/fba/updateInbound",

  /* Virtual printer */
  getPrinters: "https://login.sellershub.io/rest/getPrinters",
  deletePrinter: "https://login.sellershub.io/rest/deletePrinter",
  getPrintjob: "https://login.sellershub.io/rest/getPrintjob",
  getPrintjobs: "https://login.sellershub.io/rest/getPrintjobs",
  updatePrintjob: "https://login.sellershub.io/rest/updatePrintjob",
  createPrintjob: "https://login.sellershub.io/rest/createPrintjob",
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
