<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto">
    <li class="nav-item d-none d-md-block d-lg-none">
        <a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves-light" href="javascript:void(0)">
            <i class="mdi mdi-menu font-24"></i>
        </a>
    </li>
	<li class="d-none d-md-block">
		<form class="app-search">
            <input type="text" class="form-control" placeholder="Search..."> 
			<a class="srh-btn"><i class="ti-search"></i></a> 
		</form>
    </li>
</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">
    <li class="mr-2">
		<div class="">
        	<a href="https://www.wrappixel.com/templates/elite-angular-admin/" class="btn btn-dark text-white ml-3">Upgrade to Pro</a>
		</div>
    </li>
</ul>