<span class="hand-icon" [ngbPopover]="popContent" [autoClose]="false" popoverTitle="{{columnName}}" #p="ngbPopover" triggers="manual" >
  <i class="fa fa-filter" [ngClass]="searchString!=''?'filter-selected':'filter-not-selected'" (click)="openPopOver()"></i></span>

 <ng-template #popContent let-greeting="{{columnName}}">
  <div class="form-group">
    <select [(ngModel)]="filterKey" class="form-control custom-select" aria-placeholder="filter By" (change)="changesFilterKey()">
      <option disabled value=""> --Select Column-- </option>
      <option [value]="filters.columnFilterKey" *ngFor="let filters of multipleColumns">{{filters.columnName}}</option>
    </select>
  </div>
  <div class="input-group mb-3">
    <input [(ngModel)]="searchString" type="text" class="form-control" placeholder="{{columnName}}">
    <div class="input-group-append">
      <span (click)="saveData()" title="save" class="input-group-text" style="background-color: #fec107;"><i class="ti-save"></i></span>
    </div>
    <div class="input-group-append">
        <span (click)="closePopOver()" title="close" class="input-group-text" style="background-color: #fec107;"><i class=" ti-close"></i></span>
    </div>
  </div>
</ng-template>
