export class CreateTicket{
    name:any;
    email:any;
    phone:any;
    query:any;
    constructor(){
        this.name = '';
        this.email = '';
        this.phone = '';
        this.query = '';
    }
}