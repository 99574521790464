<div [ngClass]="getTheme()" >
    <div id="main-wrapper">
        <app-header></app-header>
        <app-leftsidemenu></app-leftsidemenu>
        <app-rightside></app-rightside>
        <div class="page-wrapper">
            <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>
    </div>
</div>