<div class="container">


  <!-- The Modal -->
  <div class="modal" id="ebay-token" data-backdrop="static">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div class="alert alert-success" role="alert">
            Successfully Integrated
             <!-- <a href="https://login.sellershub.io/secure/login" class="alert-link">Login</a> -->
          </div>
          <!-- <div class="alert alert-danger" role="alert" *ngIf="state == 2">
            Verification failed <br/>

          </div> -->
        </div>

        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
        </div>

      </div>
    </div>
  </div>

</div>
