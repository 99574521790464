export enum Roles {
  ADMIN = "ADMIN",
  INVENTORY = "INVENTORY",
  READONLY = "READONLY",
  ORDER = "ORDER",
  CUSTOMER = "CUSTOMER",
  STOCKREPRICER = "STOCK REPRICER",
  DASHBOARD = "DASHBOARD",
  REPORTS = "REPORTS",
  LISTINGS = "LISTINGS",
  SETTINGS = "SETTINGS",
  SHIPPING = "SHIPPING",
  INTEGRATION = "INTEGRATION",
  TEMPLATEDESIGNER = "TEMPLATEDESIGNER",
  AUTOMATIONS = "AUTOMATIONS",
  CUSTOEMRREPLIES = "CUSTOEMRREPLIES",
  SUBSCRIPTION = "SUBSCRIPTION",
  USERMANAGEMENT = "USERMANAGEMENT",
  SUPPORT = "SUPPORT",
}
